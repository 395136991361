import './PrivacyPolicy.scss';

import { Link } from 'react-router-dom';
import { MobileAppPolicy } from './MobileAppPolicy';
import { WebPolicy } from './WebPolicy';
import studybuddy from '../../resources/brand/studybuddy.svg';

export const PrivacyPolicy = ({ type }: { type: 'web' | 'mobile' }) => {
  return (
    <div className="container privacy-policy">
      <div className="row">
        <div className="col">
          <h2 className="title">
            <Link to="/">
              <img src={studybuddy} />
            </Link>
          </h2>
        </div>
      </div>
      <div className="row">
        {type === 'web' && <WebPolicy />}
        {type === 'mobile' && <MobileAppPolicy />}
      </div>
    </div>
  );
};
