import { analytics } from './firebaseConfig';

export class Event {
  name: string;
  attrs?: any;
  fbEvent?: string;

  constructor(name: string, attrs?: any, fbevent?: string) {
    this.name = name;
    this.attrs = attrs;
    this.fbEvent = fbevent;
  }

  static previewFeature(index: number): Event {
    return new Event('p_preview_feature', { index: index });
  }

  static subscribeEmail(): Event {
    return new Event('p_subscribe', undefined, 'Lead');
  }

  static openSocial(social: 'fb' | 'tw' | 'em' | 'ig'): Event {
    return new Event('p_social_link_clicked', { target: social });
  }
}

export class AnalyticsService {
  static track(event: Event) {
    const fbEvent = event.fbEvent;
    const fbq = (window as any).fbq;
    analytics.logEvent(event.name, event.attrs);

    if (fbEvent && fbq) {
      fbq('track', fbEvent);
    }
  }
}
