import React from 'react';
import clock from './clock.svg';
import fire from './fire.svg';
import glasses from './glasses.svg';

import './Plans.scss';

export function Plans() {
  return (
    <div className="container plans">
      <div className="row">
        <div className="intro">
          <h3>Skutečné maturitní otázky přímo na Tvém zařízení. Kdykoliv.</h3>
          <p>
            <small>
              Máš jen 15 minut? Nevadí, délku zkoušky i její obsah je možné
              jednoduše přizpůsobit.
            </small>
          </p>
        </div>
      </div>
      <div className="row justify-content-center items">
        <div className="col-6 col-md-4 col-lg-2">
          <div className="item">
            <img src={fire} alt="5 min" />
            <p>5 min</p>
          </div>
        </div>
        <div className="col-6 col-md-4 col-lg-2">
          <div className="item">
            <img src={clock} alt="15 min" />
            <p>15 min</p>
          </div>
        </div>
        <div className="col-6 col-md-4 col-lg-2">
          <div className="item">
            <img src={glasses} alt="90 min" />
            <p>135 min</p>
          </div>
        </div>
      </div>
    </div>
  );
}
