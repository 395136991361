import React from 'react';

export const ScalableImage = (
  props: React.ImgHTMLAttributes<HTMLImageElement> & {
    standard: string;
    scaled: string;
  },
) => {
  const { standard, scaled, ...imgProps } = props;

  return (
    <img
      src={standard}
      srcSet={standard + ' 1x, ' + scaled + ' 2x'}
      {...imgProps}
    />
  );
};
