import React, { useState } from 'react';
import phoneFrame from '../../resources/phoneFrame.svg';

import './PreviewCommon.scss';
import './Preview.scss';
import { ScalableImage } from '../scalableImage/ScalableImage';

import classNames from 'classnames';
import { activities, Activity } from './Activity';
import { AnalyticsService, Event } from '../../model/AnalyticsService';

export function Preview() {
  const [activeStage, setActiveStage] = useState(0);

  return (
    <div className="container preview">
      <div className="row">
        <ActivityPreview activity={activities[activeStage]} />
        <div className="col app-preview-text">
          {activities.map((activity, index) => (
            <ActivityDescription
              key={activity.title}
              index={index}
              activity={activity}
              isActiveStage={activeStage === index}
              selectStage={() => {
                AnalyticsService.track(Event.previewFeature(index));
                setActiveStage(index);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const ActivityPreview = (props: { activity: Activity }) => {
  return (
    <div className="col app-preview">
      <div className="align-helper">
        <div className="align-helper-cell">
          <div className="highlight"></div>
        </div>
      </div>
      <div className="app-preview-wrapper">
        <img src={phoneFrame} className="frame" alt="Phone frame" />
        <ScalableImage
          standard={props.activity.imagePreview}
          scaled={props.activity.imagePreviewScaled}
          className="app-preview-content"
          alt={props.activity.title}
        />
      </div>
    </div>
  );
};

const ActivityDescription = (props: {
  index: number;
  activity: Activity;
  isActiveStage: boolean;
  selectStage: () => void;
}) => {
  return (
    <div
      className={classNames({
        row: true,
        item: true,
        'active-item': props.isActiveStage,
      })}
      key={`activity-${props.index}`}
      onClick={props.selectStage}
    >
      <div className="row item-inner">
        <div className="align-helper">
          <div className="circle">
            <div className="circle-content">{props.index + 1}</div>
          </div>
        </div>
        <div className="col">
          <h4>{props.activity.title}</h4>
          <small>{props.activity.description}</small>
        </div>
      </div>
    </div>
  );
};
