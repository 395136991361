import React, { FormEvent, useState } from 'react';

import './EmailForm.scss';
import { EmailService } from '../../model/EmailService';
import { SubmitState, SubmitStateType } from '../../model/SubmitState';
import { AnalyticsService, Event } from '../../model/AnalyticsService';

export function EmailForm(props: {
  submittingState: SubmitState;
  setSubmittingState: (s: SubmitState) => void;
}) {
  const [error, setError] = useState<string>();
  const { submittingState, setSubmittingState } = props;
  const invalidEmailMessage = 'Email nemá správný formát';
  const requiredEmailMessage = 'Email je povinný';

  const emailHasValidFormat = (email: string) => {
    return email.match(
      /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,10})$/,
    );
  };

  const validateEmailInput = (email: string) => {
    if (!email) {
      return requiredEmailMessage;
    } else if (!emailHasValidFormat(email)) {
      return invalidEmailMessage;
    }

    return undefined;
  };

  const updateEmail = (emailFormValue: string) => {
    setSubmittingState({ type: SubmitStateType.NONE, value: emailFormValue });
    const error = validateEmailInput(emailFormValue);
    setError(error);
  };

  const submit = (e: FormEvent<any>) => {
    e.preventDefault();
    const val = submittingState.value;
    const validationError = validateEmailInput(val);

    if (validationError) {
      setError(validationError);
      setSubmittingState({ type: SubmitStateType.NONE, value: val });
      return;
    }

    setSubmittingState({ type: SubmitStateType.PENDING, value: val });
    EmailService.sendEmail(val)
      .then(() => {
        AnalyticsService.track(Event.subscribeEmail());
        setSubmittingState({ type: SubmitStateType.SUCCESS, value: val });
      })
      .catch((e) => {
        console.error(e);
        setSubmittingState({ type: SubmitStateType.FAILED, value: '' });
      });
  };

  return (
    <div className="email-form">
      <form onSubmit={submit}>
        <div className="row">
          <div className="col-lg-6 col-md-12 form-group">
            <input
              type="text"
              autoComplete="email"
              className="form-control"
              placeholder="Zadejte Váš email"
              value={submittingState.value}
              onChange={(e) => updateEmail(e.target.value)}
              disabled={submittingState.type === SubmitStateType.SUCCESS}
            />
            {error && submittingState.type != SubmitStateType.SUCCESS && (
              <span className="help-block email-error">{error}</span>
            )}
          </div>
          <div className="col-lg-6 col-md-12">
            <button
              className="btn btn-primary"
              type="submit"
              onSubmit={submit}
              disabled={
                submittingState.type === SubmitStateType.PENDING ||
                submittingState.type === SubmitStateType.SUCCESS
              }
            >
              Potvrdit
              {submittingState.type === SubmitStateType.PENDING && <Loader />}
            </button>
          </div>
          {submittingState.type === SubmitStateType.FAILED && (
            <div className="alert alert-danger">
              Ups! Vypadá to, že se stala nějaká chyba, zkuste to prosím
              později.
            </div>
          )}
          {submittingState.type === SubmitStateType.SUCCESS && (
            <div className="alert alert-success">Uloženo, děkujeme!</div>
          )}
        </div>
      </form>
    </div>
  );
}

const Loader = () => {
  return (
    <div className="spinner-border ml-3" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};
