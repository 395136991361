import graduate from './graduate.svg';

import './Recap.scss';
import { EmailForm } from '../emailForm/EmailForm';
import { SubmitState } from '../../model/SubmitState';
import { StoreBadges } from '../store-badges/StoreBadges';

export function Recap(props: {
  submittingState: SubmitState;
  setSubmittingState: (s: SubmitState) => void;
}) {
  const { submittingState, setSubmittingState } = props;

  return (
    <div className="container recap">
      <div className="row">
        <div className="col-md-8 recap-text">
          <h4 className="recap-pretitle">Takže ve zkratce</h4>
          <h1>Vyhni se zbytečnému stresu z maturity spolu se Studybuddy</h1>
          <h4 className="form-title">Zůstaň v kontaktu</h4>
          <EmailForm
            submittingState={submittingState}
            setSubmittingState={setSubmittingState}
          />
        </div>
        <div className="col-md-4 image-col">
          <img src={graduate} alt="Graduate" />
        </div>
      </div>
    </div>
  );
}
