import './CookieConsent.scss';

export const CookieConsent = () => {
  return (
    <div className="row consent-container">
      Při poskytování služeb využíváme soubory cookie. Používáním webu
      vyjadřujete souhlas.&nbsp;
      <a href="/ochrana-osobnich-udaju" target="_blank">
        Ochrana soukromí – cookies.
      </a>
    </div>
  );
};
