import 'firebase/functions';
import { functions } from './firebaseConfig';

export class EmailService {
  static sendEmail(email: string): Promise<any> {
    const subscribe = functions.httpsCallable('prelaunch-subscribeNews');

    return subscribe({
      email: email,
      query: window.location.search,
    });
  }
}
