import React from 'react';
import './Intro.scss';
import arrowDown from './arrow-down.svg';
import studybuddy from '../../resources/brand/studybuddy.svg';
import homeScreen from './assets/home.png';
import homeScreen2x from './assets/home@2x.png';
import phoneFrame from '../../resources/phoneFrame.svg';
import { EmailForm } from '../emailForm/EmailForm';
import { SubmitState } from '../../model/SubmitState';
import { ScalableImage } from '../scalableImage/ScalableImage';
import { StoreBadges } from '../store-badges/StoreBadges';

export function Intro(props: {
  submittingState: SubmitState;
  setSubmittingState: (s: SubmitState) => void;
}) {
  return (
    <div className="container intro">
      <div className="row">
        <div className="col-md-8 col-lg-7 overview-text">
          <h2 className="intro-title">
            <img src={studybuddy} />
          </h2>
          <h1 className="intro-subtitle">
            Maturita v kapse.
            <br />
            Připrav se na zkoušku s mobilní aplikací Studybuddy.
          </h1>
          <small>
            Procvičování, maturita nanečisto i krátké kvízy připravené na míru
            pro tebe.
            <br /> Mobilní aplikace Studybuddy je nyní dostupná pro Android a
            brzy i pro iOS. Úplně zdarma.
          </small>
          <StoreBadges />
          <ScrollIndicator />
        </div>
        <div className="col-md-4 offset-lg-1">
          <div className="app-preview">
            <img src={phoneFrame} alt="logo" />

            <ScalableImage
              standard={homeScreen}
              scaled={homeScreen2x}
              alt="Dashboard uživatele"
              className="app-preview-content"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const ScrollIndicator = () => {
  return (
    <div className="scroll-indicator">
      <img src={arrowDown} />
      <img src={arrowDown} />
      <img src={arrowDown} />
    </div>
  );
};
