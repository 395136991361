import instagram from './insta.svg';
import twitter from './twitter.svg';
import facebook from './fb.svg';
import email from './mail.svg';

export interface Social {
  id: 'fb' | 'tw' | 'em' | 'ig';
  name: string;
  icon: string;
  destination: string;
}

export const socials: Social[] = [
  {
    id: 'ig',
    name: 'Instagram',
    icon: instagram,
    destination: 'https://instagram.com/studybuddycz',
  },
  {
    id: 'tw',
    name: 'Twitter',
    icon: twitter,
    destination: 'https://twitter.com/studybuddycz',
  },
  {
    id: 'fb',
    name: 'Facebook',
    icon: facebook,
    destination: 'https://facebook.com/studybuddycz',
  },
  {
    id: 'em',
    name: 'Email',
    icon: email,
    destination: 'mailto:podpora@studybuddy.cz',
  },
];
