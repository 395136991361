export enum SubmitStateType {
  NONE,
  PENDING,
  SUCCESS,
  FAILED,
}

export interface SubmitState {
  type: SubmitStateType;
  value: string;
}
