import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { Intro } from './component/intro/Intro';
import { Plans } from './component/plans/Plans';
import { Recap } from './component/recap/Recap';
import { Footer } from './component/footer/Footer';
import { Preview } from './component/preview/Preview';
import { SubmitState, SubmitStateType } from './model/SubmitState';
import { PreviewSmall } from './component/preview/PreviewSmall';
import { PrivacyPolicy } from './component/privacy-policy/PrivacyPolicy';

function App() {
  const [submittingState, setSubmittingState] = useState<SubmitState>({
    value: '',
    type: SubmitStateType.NONE,
  });

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/ochrana-osobnich-udaju">
            <Page title="Ochrana soukromí">
              <PrivacyPolicy type="web" />
            </Page>
          </Route>
          <Route path="/ochrana-osobnich-udaju-app">
            <Page title="Zásady ochrany osobních údajů">
              <PrivacyPolicy type="mobile" />
            </Page>
          </Route>
          <Route path="/">
            <Page title="Maturita v kapse">
              <PrelaunchContent
                state={submittingState}
                setSubmittingState={setSubmittingState}
              />
            </Page>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

const Page = (props: React.PropsWithChildren<{ title: string }>) => {
  useEffect(() => {
    document.title = `Studybuddy - ${props.title}`;
  });

  return <Fragment>{props.children}</Fragment>;
};

const PrelaunchContent = (props: {
  state: SubmitState;
  setSubmittingState: (s: SubmitState) => void;
}) => {
  return (
    <Fragment>
      <Intro
        submittingState={props.state}
        setSubmittingState={props.setSubmittingState}
      />
      <Plans />
      <Preview />
      <PreviewSmall />
      <Recap
        submittingState={props.state}
        setSubmittingState={props.setSubmittingState}
      />
      <Footer />
    </Fragment>
  );
};

export default App;
