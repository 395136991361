import quiz from './assets/quiz.png';
import quiz2x from './assets/quiz@2x.png';
import composer from './assets/composer.png';
import composer2x from './assets/composer@2x.png';
import results from './assets/results.png';
import results2x from './assets/results@2x.png';

export interface Activity {
  title: string;
  description: string;
  imagePreview: string;
  imagePreviewScaled: string;
}

export const activities: Activity[] = [
  {
    title: 'Příklady jako při maturitě',
    description:
      'Obsah tvořený pedagogy tak, aby tě během skutečné zkoušky už nic nepřekvapilo.',
    imagePreview: quiz,
    imagePreviewScaled: quiz2x,
  },
  {
    title: 'Procvič to, co momentálně nejvíc potřebuješ',
    description:
      'V klidu doma nebo cestou do školy, Studybuddy ti připraví zkoušku na míru.',
    imagePreview: composer,
    imagePreviewScaled: composer2x,
  },
  {
    title: 'Měj přehled o výsledcích',
    description: 'Analyzuj průběžně své výsledky a sleduj, jak se zlepšuješ.',
    imagePreview: results,
    imagePreviewScaled: results2x,
  },
];
