import { GOOGLE_PLAY_STORE_LINK } from '../../model/Constants';
import GooglePlayBadgeImg from '../../resources/play-badge.svg';

export const StoreBadges = () => {
  return (
    <div className="store-badges">
      <GooglePlayAppBadge />
    </div>
  );
};

const GooglePlayAppBadge = () => {
  return (
    <a href={GOOGLE_PLAY_STORE_LINK}>
      <img src={GooglePlayBadgeImg} alt="Ke stažení na Google Play" />
    </a>
  );
};
