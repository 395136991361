import React from 'react';

import './Footer.scss';
import { AnalyticsService, Event } from '../../model/AnalyticsService';
import { Social, socials } from './Social';
import { CookieConsent } from '../cookie-consent/CookieConsent';
import { StoreBadges } from '../store-badges/StoreBadges';

export function Footer() {
  return (
    <footer>
      <div className="container footer">
        <h4>Sleduj nás na sociálních sítích</h4>
        <div className="socials-container">
          {socials.map((social) => (
            <SocialLink social={social} key={social.id} />
          ))}
          <StoreBadges />
        </div>

        <CookieConsent />
      </div>
    </footer>
  );
}

function SocialLink(props: { social: Social }) {
  return (
    <a
      href={props.social.destination}
      onClick={() => AnalyticsService.track(Event.openSocial(props.social.id))}
      className="social-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="align-helper">
        <img src={props.social.icon} alt={props.social.name} />
      </div>
    </a>
  );
}
