export const WebPolicy = () => {
  return (
    <div className="col-lg-8">
      <h3>Ochrana soukromí a cookies</h3>
      <p>
        Chceme co nejvíce zaručit Vaše soukromí a uživatelskou přívětivost
        webových stránek Studybuddy. Níže si můžete přečíst další informace o
        používaných souborech cookies a za jakým účelem jsou cookies používány.
      </p>
      <h4>Prohlášení o použití cookies</h4>
      <p>
        Tyto webové stránky ukládají v souladu se zákony na vaše zařízení
        soubory, obecně nazývané cookies.
      </p>
      <h5>Co jsou cookies?</h5>
      <p>
        Cookies jsou malé datové soubory, díky kterým si navštívené webové
        stránky stránky pamatují vaše úkony a nastavení, které jste na nich
        provedli, takže tyto údaje nemusíte zadávat opakovaně. Cookies
        nepředstavují nebezpečí, mají však význam pro ochranu soukromí. Cookies
        nelze použít pro zjištění totožnosti návštěvníků stránek ani ke zneužití
        přihlašovacích údajů.
      </p>
      <h5>Jaké cookies používáme?</h5>
      <p>
        Používáme cookies například pro zachování přepnutí zobrazení z mobilních
        zařízení do PC verze webu nebo další pro zachování vašich preferencí při
        prohlížení těchto webových stránek. Podrobnější informace získáte na
        níže uvedeném kontaktu na provozovatele webu. Dále používáme cookies
        třetích stran (např. Google Analytics pro analýzu návštěvnosti). Tyto
        cookies jsou řízeny třetími stranami a nemáme přístup ke čtení nebo
        zápisu těchto dat.
      </p>
      <h5>Souhlas s ukládáním cookies</h5>
      <p>
        Většina prohlížečů cookies automaticky akceptuje, pokud není prohlížeč
        nastaven jinak. Používáním těchto webových stránek souhlasíte s
        ukládáním souborů cookies. Použití cookies můžete omezit nebo zablokovat
        v nastavení svého webového prohlížeče. Informace o nastavení konkrétního
        prohlížeče naleznete na uvedených adresách:
      </p>
      <ul>
        {browsers.map((browser) => {
          return (
            <li>
              {browser.name}:{' '}
              <a
                href={'https://' + browser.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {browser.link}
              </a>
            </li>
          );
        })}
      </ul>
      <h4>Zpracování osobních údajů</h4>
      <p>
        Pro úspěšné využívání služeb, které tato webová stránka nabízí, v
        některých případech potřebujeme zpracovávat osobní údaje uživatelů.
      </p>

      <p>
        Toto zpracovávání podléhá nařízení Evropského parlamentu a Rady 2016/679
        ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se
        zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení
        směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů). Známé také
        jako &bdquo;GDPR&ldquo;.
      </p>

      <h5>Služby využívající osobní údaje</h5>
      <p>
        Uvedené osobní údaje zpracováváme pouze pro účely níže popsané. Osobní
        údaje neprodáváme ani neposkytujeme třetím stranám. Souhlas považujeme
        za platný do jeho odvolání, viz níže.
      </p>

      <h5>Odběr novinek e-mailem</h5>
      <p>
        Pro využívání služby odběru novinek e-mailem potřebujeme znát Vaši
        e-mailovou adresu. Tento údaj neuvádíme veřejně a slouží pouze pro
        potřeby odesílání e-mailů s novinkami.
      </p>

      <h5>Webové formuláře</h5>
      <p>
        Při odesílání webových formulářů na této webové stránce ukládáme Vaši
        e-mailovou adresu jako kontaktní údaj pro odpovědi. Tento údaj neuvádíme
        veřejně.
      </p>

      <p>
        Webové formuláře mohou pro úspěšné odeslání požadovat další osobní
        údaje. V takových případech tyto údaje zpracováváme pouze pro potřeby
        vyhodnocení formuláře a neuvádíme je veřejně.
      </p>

      <h5>Odvolání souhlasu</h5>
      <p>
        V případě, že si budete přát odvolat souhlas se zpracováním osobních
        údajů, obraťte se na provozovatele stránek viz níže.
      </p>

      <h3>Kontakt</h3>
      <p>
        Provozovatelem webových stránek Studybuddy na doméně www.studybuddy.cz
        je Josef Doležal, IČ: 05701457.
      </p>
      <p>
        Kontakt:{' '}
        <a href="mailto:podpora@studybuddy.cz">podpora@studybuddy.cz</a>
      </p>
    </div>
  );
};

const browsers = [
  { name: 'Internet Explorer', link: 'windows.microsoft.com' },
  { name: 'Google Chrome', link: 'support.google.com' },
  { name: 'Mozilla Firefox', link: 'support.mozilla.org' },
  { name: 'Opera', link: 'help.opera.com' },
  { name: 'Safari', link: 'support.apple.com' },
];
