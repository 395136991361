export const MobileAppPolicy = () => {
  return (
    <div className="col-lg-8">
      <h3>Zásady ochrany osobních údajů mobilní aplikace Studybuddy</h3>
      <p>
        Při zpracování osobních údajů dbáme na ochranu soukromí. Osobní údaje
        jsou zpracovávány pouze zákonným způsobem a pro zákonem stanovené účely.
        Veškeré zásady zpracování osobních údajů, stanovené v čl. 5 Nařízení
        Evropského parlamentu a Rady (EU) 2016/679, o ochraně osobních údajů
        (GDPR), tedy zákonnost, korektnost a transparentnost, účelové omezení,
        minimalizace údajů, přesnost, omezení uložení, integrita a důvěrnost a v
        neposlední řadě odpovědnost, jsou zajištěny. V souladu se zásadou
        minimalizace jsou osobní údaje uchovávány pouze po nezbytně nutnou dobu,
        po jejíž uplyntí jsou bezpečně vymazány.
      </p>

      <h4>Zásady ochrany osobních údajů</h4>

      <p>
        Pro sledování využití mobilní aplikace &bdquo;Studybuddy&ldquo;
        získáváme statistické údaje o přístupech na jednotlivé obrazovky. Jedná
        se o následující údaje:
      </p>
      <p>
        <ul>
          <li>Průchod uživatele aplikací (návštěva jednotlivých obrazovek)</li>
          <li>Rychlost načtení aplikace</li>
          <li>
            Sessions - Spuštění aplikace - informace o celkovém počtu spuštění a
            počtu spuštění v jednotlivých hodinách dne za dané období a počet
            spuštění v jednotlivých hodinách dne
          </li>
          <li>Návštěvy - informace o době strávené v aplikaci</li>
          <li>
            Uživatelé / Zařízení - verze operačního systému a/nebo typ zařízení
          </li>
          <li>
            Uživatelé / Lokalita - informace o zemi a městu/obci (anonymizováno)
          </li>
          <li>Selhání - údaje týkajících se problému v aplikaci</li>
        </ul>
        <p>
          Pro monitoring a vyhodnocení výše uvedených statistických údajů je
          použita služba Google Firebase (Crashlytics). Data jsou uložena na
          serverech společnosti Google.
        </p>

        <p>
          V aplikaci nejsou umožněny nákupy a ani není zobrazována reklama a
          inzerce. Data nejsou poskytována třetím stranám.
        </p>
      </p>
    </div>
  );
};
