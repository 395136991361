import { activities } from './Activity';
import React from 'react';
import phoneFrame from '../../resources/phoneFrame.svg';

import './PreviewCommon.scss';
import './PreviewSmall.scss';

export function PreviewSmall() {
  return (
    <div className="container preview-sm">
      {activities.map((activity, index) => (
        <div className="preview-sm-item" key={`activity-${index}`}>
          <div className="align-helper">
            <div className="circle">
              <div className="circle-content">{index + 1}</div>
            </div>
          </div>
          <h3>{activity.title}</h3>
          <small>{activity.description}</small>
          <div className="preview-container">
            <div className="app-preview-wrapper">
              <img src={phoneFrame} className="frame" alt="logo" />
              <img
                src={activity.imagePreview}
                className="app-preview-content"
                alt="logo"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
